@import url('./assets/lib/hljs/styles/atom-one-light.css');
@import url('../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css');
@import url('../node_modules/@fortawesome/fontawesome-free/css/all.min.css');
@import './assets/scss/app.scss';
@import '~@ctrl/ngx-emoji-mart/picker';


.logo img{
    height: 90%;
    border-radius: 50%;
}
.ant-tree-switcher{
    width: 66px;
}

.ant-tree .ant-tree-treenode{
    margin-bottom: 21px;
}